var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "请选择消息类型", prop: "typeCode" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择消息类型", "allow-clear": "" },
                  model: {
                    value: _vm.form.typeCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "typeCode", $$v)
                    },
                    expression: "form.typeCode",
                  },
                },
                _vm._l(_vm.msgTypeArray, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.typeCode } },
                    [_vm._v(_vm._s(d.typeName))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "消息内容", prop: "content" } },
            [
              _c("a-textarea", {
                model: {
                  value: _vm.form.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "content", $$v)
                  },
                  expression: "form.content",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }