import request from '@/utils/request'

// 查询消息通知内容列表
export function listNotification (query) {
  return request({
    url: '/message/notification/list',
    method: 'get',
    params: query
  })
}

// 查询消息通知内容详细
export function getNotification (id) {
  return request({
    url: '/message/notification/' + id,
    method: 'get'
  })
}

// 新增消息通知内容
export function addNotification (data) {
  return request({
    url: '/message/notification',
    method: 'post',
    data: data
  })
}
// 消息发送测试
export function sendMessage (data) {
  return request({
    url: '/message/notification/sendMessage',
    method: 'post',
    data: data
  })
}
// 消息发送测试
export function sendMessageByTypeChannel (data) {
  return request({
    url: '/message/notification/sendMessageByTypeChannel',
    method: 'post',
    data: data
  })
}
// 消息发送测试
export function sendNoticeVehicleWarning (data) {
  return request({
    url: '/message/notification/noticeVehicleWarning',
    method: 'post',
    data: data
  })
}

// 修改消息通知内容
export function updateNotification (data) {
  return request({
    url: '/message/notification',
    method: 'put',
    data: data
  })
}

// 删除消息通知内容
export function delNotification (id) {
  return request({
    url: '/message/notification/' + id,
    method: 'delete'
  })
}

// 导出消息通知内容
export function exportNotification (query) {
  return request({
    url: '/message/notification/export',
    method: 'get',
    params: query
  })
}
